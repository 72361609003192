import React from "react";
import {graphql, useStaticQuery} from "gatsby";
import Layout from "../../components/layout";
import ProjectComponent from "../../components/projects/projectComponent";

import bell from "../../images/projects/spot/icons/bell.svg";
import cog from "../../images/projects/spot/icons/cog.svg";
import cross from "../../images/projects/spot/icons/cross.svg";

import arrow_left from "../../images/projects/spot/icons/arrow-left.svg";
import phone from "../../images/projects/spot/icons/phone.svg";
import commenting_o from "../../images/projects/spot/icons/commenting-o.svg";

const ProjectSpot = () => {
    const data = useStaticQuery(graphql`
        query {
            banner: file(relativePath: {eq: "projects/spot/spot-club-banner.jpg"}) {
                childImageSharp {
                    gatsbyImageData
                }
            }
            picture: file(relativePath: {eq: "projects/spot/spot-club-picture.jpg"}) {
                childImageSharp {
                    gatsbyImageData
                }
            }
        }
    `);

    const projectData = {
        project: "spot",
        banner: data.banner.childImageSharp.gatsbyImageData,
        stages: {
            about: true,
            design: true,
            tech: true,
            result: true,
        },
        colors: ["#000000", "#f8f8f8"],
        font: "",
        icons: [bell, cog, cross, arrow_left, phone, commenting_o],
        picture: data.picture.childImageSharp.gatsbyImageData,
        googlePlay: "https://play.google.com/store/apps/details?id=com.smokespot",
        appStore: "https://apps.apple.com/ru/app/spot-club/id1467807336",
    };

    return (
        <Layout>
            <ProjectComponent projectData={projectData} />
        </Layout>
    );
};

export default ProjectSpot;
